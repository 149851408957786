<!--
 * @Description: 
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-15 14:20:42
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '代理商',
          list: [
            {
              name: '全部代理商',
              path: '/admin/agent/agents'
            },
            {
              name: '正常使用',
              path: '/admin/agent/normal?index='+2
            },
            {
              name: '已禁用',
              path: '/admin/agent/disable?index='+3
            }
          ]
        }
      ]
    };
  }
};
</script>
